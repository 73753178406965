<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcContactForm contentModule hSpace hSpace--colGap" :class="elmClasses">
		<div class="DcContactForm__inner">
			<div class="DcContactForm__before grid grid--cols-8" :class="beforeClasses">
				<form class="DcContactForm__form hSpace hSpace--colGap vSpace vSpace--rowGap" @submit="onSubmitForm">
					<div class="DcContactForm__formBody grid grid--cols-6">
						<input 		class="DcContactForm__input" 	v-model="formData.name" 	placeholder="Vor- und Nachname" type="text" />
						<input 		class="DcContactForm__input" 	v-model="formData.phone" 	placeholder="Telefon" type="text" />
						<input 		class="DcContactForm__input" 	v-model="formData.mail" 	placeholder="Email-Adresse*" type="email" required/>
						<textarea 	class="DcContactForm__textarea" v-model="formData.message" 	placeholder="Ihre Nachricht*" required></textarea>
					</div>
					<div class="DcContactForm__formFooter flex flex--right vSpace vSpace--rowGap">
						<button class="DcContactForm__submitBtn btn btn--submitContactForm">Abschicken</button>
					</div>
				</form>
			</div>
			<div class="DcContactForm__after grid grid--cols-8" :class="afterClasses">
				<div class="DcContactForm__successMessage color color--magenta">
					Das hat geklappt!
					Danke für Ihre Nachricht und bis bald.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'

	export default {
		name: 'DcContactForm',
		components: {},
		mixins: [
			RestHandler,
		],
		props: {},
		data() {
			return {
				doShowBefore : true,
				doShowAfter : false,
				formData : {
					name    : '',
					phone   : '',
					mail    : '',
					message : '',
				}
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			beforeClasses(){
				return this.doShowBefore ? 'DcContactForm__before--isVisble' : 'DcContactForm__before--isHidden'
			},
			afterClasses(){
				return this.doShowAfter ? 'DcContactForm__after--isVisble' : 'DcContactForm__after--isHidden'
			}
		},
		methods: {
			onSubmitForm( e ){
				const doLog    = false
				const formData = this.formData

				// debug log
				if( doLog ) console.group('onSubmitForm()')
				this._.forEach( formData, (value, key) => {
					if( doLog ) console.log(key + ': ' + value)
				})
				if( doLog ) console.groupEnd()

				// prevent reload, handle submit via ajax
				e.preventDefault()

				// hide form, show message
				this.doShowBefore = false
				this.doShowAfter  = true

				// just for debugging
				setTimeout( ()=>{
					this.doShowBefore = true
					this.doShowAfter  = false
				}, 5000 )

				// submit data
				this.restHandler__fetch({
					action : 'POST',
					route : '/wp-json/mh/v1/dc__contactForm',
					params : {},
					data : this.formData,
					callback : (response) => {
						const doLog  = false
						const result = response.data.result
						const count  = this._.size( result )

						if( doLog ){
							console.groupCollapsed('this.fetchAcfOptions() callback', count)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						// reset formData
						this._.forEach( this.formData, (value, key) => {
							this.formData[key] = ''
						})
					},
				})
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcContactForm {
		&__inner {
			position: relative;
		}
		&__form {
			background-color: @swatches[magenta];

			//display: flex;
			grid-column: span 6;
			grid-column-start: 2;
			grid-column-end: 8;

			border-radius: 7px;
		}
		&__formFooter {
			padding-bottom: 0;
		}
		&__input,
		&__textarea {
			.font--sans;
			.font--sizeSmall;

			margin: 0;
			border: none;
			outline: none;
			height: 43px;
			padding-left: 0.75em;
			border-radius: 7px;

			&::-webkit-input-placeholder, // Edge
			&:-ms-input-placeholder,  // Internet Explorer 10-11
			&::placeholder {
				color: inherit;
			}
			*:focus::-webkit-input-placeholder, // Edge
			*:focus:-ms-input-placeholder,  // Internet Explorer 10-11
			*:focus::placeholder {
				opacity: 0;
			}
		}
		&__input {
			grid-column: span 3;
		}
		&__textarea {
			grid-column: span 6;
			padding-top: 0.75em;
			height: 215px;
			resize: vertical;
		}

		&__successMessage {
			grid-column: span 6;
			grid-column-start: 2;
			grid-column-end: 8;
		}

		&__before {
			transition: all 0.2s ease;
		}
		&__before--isVisble {
			transition-delay: 0.2s;
			opacity: 1;
		}
		&__before--isHidden {
			opacity: 0;
			pointer-events: none;
		}
		&__after {
			transition: all 0.2s ease;
			position: absolute;
			top: 0; left: 0; width: 100%;
		}
		&__after--isVisble {
			transition-delay: 0.2s;
			opacity: 1;
		}
		&__after--isHidden {
			opacity: 0;
			pointer-events: none;
		}

		@media @mediaQuery__dt {
			&__successMessage {
				.font--sizeBig;

				grid-column: span 6;
				grid-column-start: 2;
				grid-column-end: 8;
			}
		}
		@media @mediaQuery__md {
			&__successMessage {
				.font--sizeBig;

				grid-column: span 6;
				grid-column-start: 2;
				grid-column-end: 8;
			}
		}
		@media @mediaQuery__sm {
			&__form {
				grid-column: span 8;
			}
			&__input {
				grid-column: span 6;
			}
			&__textarea {
				grid-column: span 6;
			}

			&__input,
			&__textarea {
				height: 33px;
			}
			&__textarea {
				height: 140px;
				padding-top: 0.65em;
			}
			&__submitBtn {
				padding-left: 0.65em;
				padding-right: 0.65em;
			}

			&__successMessage {
				.font--sizeDefault;

				grid-column: span 8;
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
