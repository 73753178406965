<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcPrivacyPolicy contentModule background background--magenta hSpace hSpace--colGap vSpace vSpace--rowGap" :class="elmClasses" v-if="text">
		<div class="DcPrivacyPolicy__inner hSpace hSpace--colGap vSpace vSpace--rowGap">
			<h2 class="color color--magenta">{{title}}</h2>
			<div v-html="text"></div>
		</div>
		<!--
		<pre name="title">{{title}}</pre>
		<pre name="text">{{text}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DcPrivacyPolicy',
		components: {},
		mixins: [],
		props: {
			title : [String, Boolean],
			text  : [String, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcPrivacyPolicy {
		&__inner {
			background-color: white;
			border-radius: 7px;
		}

		@media @mediaQuery__dt {
			&:first-child { margin-top: -@app[dtColGap]; }
			//& + & { margin-top: -@app[dtColGap]; }
			padding-bottom: 0;
		}
		@media @mediaQuery__md {
			&:first-child { margin-top: -@app[mdColGap]; }
			//& + & { margin-top: -@app[mdColGap]; }
			padding-bottom: 0;
		}
		@media @mediaQuery__sm {
			&:first-child { margin-top: 0; padding-top: 0 }
			//& + & { margin-top: 0; }

			padding-bottom: 0;
			//padding-top: 0;

			&__inner { .font--sizeMini; }
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
