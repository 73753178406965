<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcImageText contentModule hSpace hSpace--colGap" :class="elmClasses" v-if="image && text">
		<div class="DcImageText__inner background grid grid--cols-8 vSpace vSpace--rowGap" :class="innerElmClasses">
			<div class="DcImageText__spacer DcImageText__spacer--1"></div>
			<MhImage class="DcImageText__image"
				:mode="$root.isMqBelowDt ? 'img' : 'img'"
				:useRelativeUrl="true"
				:imageObject="image"
				:title="_.get( image, 'title' )"
				:alt="_.get( image, 'alt' )"
			></MhImage>
			<div class="DcImageText__spacer DcImageText__spacer--2"></div>
			<div class="DcImageText__text" v-html="text"></div>
			<div class="DcImageText__spacer DcImageText__spacer--3"></div>
		</div>
		<!--
		<pre name="image">{{image}}</pre>
		<pre name="text">{{text}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcImageText',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			image : [Object, Boolean],
			text  : [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			innerElmClasses(){
				const classes = []

				if( this.app.bodyColor ) classes.push('background--' + this.app.bodyColor)

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		beforeDestroy(){},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcImageText {
		&__inner {}

		&__image {
			[showBorders4] & { .relative; .outline( green ); }

			grid-column: span 2;
		}
		&__text {
			[showBorders4] & { .relative; .outline( green ); }

			grid-column: span 3;
		}
		&__spacer {
			[showBorders4] & { .relative; .outline( yellow ); }

			min-height: 1em;
			background-color: inherit;
		}

		@media @mediaQuery__dt {
			&__text {
				padding-top: 1em;
				padding-bottom: 1.1em;
			}

			&__image {
				grid-column: span 4;
				padding: 10px 20px;
				display: flex;
				align-items: center;
			}

			&__spacer--1 { display: none; }
			&__spacer--2 { display: none; }
		}
		@media @mediaQuery__md {
			&__inner {
				border-radius: 7px;
			}
			&__image,
			&__text {
				padding-top: 1em;
				padding-bottom: 1em;
			}

			&__image {
				grid-column: span 8;
			}
			&__text {
				grid-column-start: 2;
				grid-column-end: 8;
			}
			&__spacer--1 { display: none; }
			&__spacer--2 { display: none; }
			&__spacer--3 { grid-column: span 1; }
		}
		@media @mediaQuery__sm {
			.font--sizeSmall;

			&__inner {
				padding: 11px;
			}

			&__image,
			&__text {
				padding-top: 0em;
				padding-bottom: 8px;
			}

			&__image {
				grid-column: span 8;
			}
			&__text {
				grid-column: span 8;
			}
			&__spacer {
				display: none;;
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
