<template>
	<div class="PageView view" :class="elmClasses">

		<template v-if="post">
			<div
				:is="contentModule.acf_fc_layout"
				:class="contentModuleClasses( index, post.acf.contentModules.length )"
				v-for="(contentModule, index) in post.acf.contentModules" :key="index"
				v-bind="contentModule"
			></div>
		</template>

		<template v-if="is404">
			<Dc404></Dc404>
		</template>

		<div class="view__spacer"></div>

		<DcFooter></DcFooter>

		<!--
		<pre name="post">{{post}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import ViewMixin from './View.mixin.js'

	import DcText from '@/components/DcText.vue'
	import DcFeatures from '@/components/DcFeatures.vue'
	import DcOffers from '@/components/DcOffers.vue'
	import DcExperiences from '@/components/DcExperiences.vue'
	import DcLocations from '@/components/DcLocations.vue'
	import DcContactForm from '@/components/DcContactForm.vue'
	import DcPrivacyPolicy from '@/components/DcPrivacyPolicy.vue'
	import DcImageText from '@/components/DcImageText.vue'
	import DcHeader from '@/components/DcHeader.vue'
	import Dc404 from '@/components/Dc404.vue'
	import DcFooter from '@/components/DcFooter.vue'
	import DcTeam from '@/components/DcTeam.vue'

	export default {
		name: 'PageView',
		components: {
			DcHeader,
			DcText,
			DcFeatures,
			DcOffers,
			DcExperiences,
			DcLocations,
			DcContactForm,
			DcPrivacyPolicy,
			DcImageText,
			DcTeam,
			Dc404,
			DcFooter,
		},
		mixins: [
			RestHandler,
			ViewMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				post  : null,
				is404 : false,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.app.bodyColor ) classes.push('underline underline--' + this.app.bodyColor )

				return classes
			},
		},
		methods: {
			fetchPost( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route  : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('%cthis.fetchPost() callback', 'background-color : lightgrey', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.post = result[0]

						callback()
					},
				})
			},
			contentModuleClasses( index, length ){
				let classes = []

				if( index == length-1 ) classes.push('contentModule--last')

				classes.push('contentModule--' + index )

				return classes
			},
		},
		mounted() {
			this.fetchPost(()=>{
				EventBus.$emit('pageLoaded', this.post)

				if( this._.isNull( this.post) ){ this.is404 = true }

				this.viewIsReady = true
			})
		},
	}
</script>

<style lang="less">
	@import "../less/includes/grid.include.less";

	.PageView {}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
