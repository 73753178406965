<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcLocations contentModule" :class="elmClasses" v-if="locations">
		<div class="DcLocations__inner background background--magenta grid grid--cols-8 hSpace hSpace--colGap vSpace vSpace--rowGap">
			<div class="DcLocations__card card" v-for="(location, index) in locations" :key="index">
				<div class="card__inner">
					<h3 class="DcLocations__title">{{location.title}}</h3>
					<div class="DcLocations__text color color--magenta" v-html="location.text"></div>
					<a class="DcLocations__btn btn color color--black" :href="location.mapsLink" v-if="location.mapsLink">
						Karte <template v-if="!$root.isMqBelowMd">anzeigen</template>
					</a>
				</div>
			</div>
		</div>
		<!--
		<pre name="locations">{{locations}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DcLocations',
		components: {},
		mixins: [],
		props: {
			locations: [Array, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcLocations {
		.font--sizeDefault;

		&__inner {}

		&__card {
			grid-column: span 4;
		}
		&__text {
			padding-bottom: 0.85em;
		}
		&__btn {
			position: absolute;
			top: 2px; right: 0;
		}

		@media @mediaQuery__md {
			&__card {
				grid-column: span 8;
			}
		}
		@media @mediaQuery__sm {
			.font--sizeSmall;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
