<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcFeatures contentModule hSpace hSpace--colGap background background--grun hyphenate" :class="elmClasses">
		<div class="DcFeatures__inner grid grid--cols-8 vSpace vSpace--rowGap">
			<router-link
				class="DcFeatures__feature"
				 v-for="post in posts" :key="post.id"
				:to="{ name: 'FeatureView', params: { slug : post.slug } }">
				<div class="DcFeatures__imageWrapper">
					<MhImage class="DcFeatures__image"
						mode="contain"
						:useRelativeUrl="true"
						:useBackgroundImage="$root.isIE"
						:position="$root.isMqBelowDt ? 'left bottom' : 'center bottom'"
						:title="_.get( post, 'acf.image.title' )"
						:alt="_.get( post, 'acf.image.alt' )"
						:imageObject="post.acf.image"></MhImage>
				</div>
				<h2 class="DcFeatures__title" v-html="post.acf.title"></h2>
			</router-link>
		</div>
		<!--
		<pre name="posts">{{posts}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcFeatures',
		components: {
			MhImage,
		},
		mixins: [ RestHandler ],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				posts : null,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchFeatures( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'feature_post',
						perPage : 99,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('this.fetchFeatures() callback', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.posts = result

						callback()
					},
				})
			},
		},
		created() {},
		mounted() {
			this.fetchFeatures()
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcFeatures {
		&__feature {
			[showBorders4] & { .relative; .outline( blue ); }

			grid-column: span 2;
			display: block;
			color: inherit;
		}
		&__imageWrapper {	// image ratio 1:1
			[showBorders4] & { outline: 1px solid red; }

			position: relative;
			width: 100%;
			padding-bottom: 100%;
		}
		&__image {	// full span image wrapper
			[showBorders4] & { background-color: fade( red, 25 ); }

			position: absolute;
			top: 0; left: 0;
			height: 100%; width: 100%;
		}
		&__title {
			padding-top: 0.5em;
			padding-bottom: 1.25em;
		}

		@media @mediaQuery__dt {
			padding-top: 45px;
			padding-bottom: 70px;

			&__title {
				padding-left: 1em;
				padding-right: 1em;
				text-align: center;
			}
		}
		@media @mediaQuery__md {
			padding-top: 30px;
			padding-bottom: 70px;
		}
		@media @mediaQuery__sm {
			.font--sizeMini;

			padding-top: 11px;
			padding-bottom: 20px;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
