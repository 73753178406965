<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcOffers contentModule " :class="elmClasses">
		<div class="DcOffers__inner background background--rot hSpace hSpace--colGap vSpace vSpace--rowGap">
			<div class="DcOffers__header">
				<h2>
					Unsere Angebote <br />
					im Überblick
				</h2>
				<button class="DcOffers__toggleFilterBtn btn"
					role="button" v-if="$route.query.filter" @click="setFilter()">
					<div>
						<span></span>
						<span></span>
					</div>
					{{$route.query.filter}}
				</button>
			</div>
			<div class="DcOffers__body grid grid--cols-8">
				<div class="DcOffers__card card" v-for="post in filteredPosts" :key="post.id">
					<div class="card__inner flex flex--column">
						<router-link class="color color--inherit"
							:to="{ name: 'OfferView', params: { slug : post.slug } }">
							<h6 class="DcOffers__overTitle">{{post.acf.overTitle}}</h6>
							<h3 class="DcOffers__title color color--rot">{{post.acf.title}}</h3>
						</router-link>
						<div class="DcOffers__spacer flex--grow"></div>
						<router-link :to="{ name: 'OfferView', params: { slug : post.slug } }">
							<MhImage class="DcOffers__image"
								:imageObject="post.acf.image"
								:title="_.get( post, 'acf.image.title' )"
								:alt="_.get( post, 'acf.image.alt' )"
								:useRelativeUrl="true"
							></MhImage>
						</router-link>
						<button class="DcOffers__filterBtn btn color color--rot"
							role="button" @click="setFilter( post.acf.route )" v-if="post.acf.route"
						>{{post.acf.route}}</button>
					</div>
				</div>

			</div>
		</div>
		<!--
		<pre>{{posts}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcOffers',
		components: {
			MhImage,
		},
		mixins: [ RestHandler ],
		props: {},
		data() {
			return {
				posts : [],
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			filteredPosts(){
				let posts          = []
				const activeFilter = this.$route.query.filter

				if( activeFilter ){
					this._.forEach( this.posts, (post)=>{
						const postFilter = post.acf.route ? post.acf.route : false

						if( this._.trim( postFilter ) == activeFilter ) posts.push( post )
					})
				}
				else{
					posts = this.posts
				}

				return posts
			},
		},
		methods: {
			fetchOffers( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'offer_post',
						perPage : 99,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('%cthis.fetchOffers() callback', 'background-color : lightgrey', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.posts = result

						callback()
					},
				})
			},
			setFilter( filterSlug ){
				const activeFilter = this.$route.query.filter
				filterSlug = this._.trim( filterSlug )

				if( filterSlug ){
					if( activeFilter != filterSlug ){ // prevent navigation duplicate error
						this.$router.push({query: { filter: filterSlug }})
					}
				}else{
					this.$router.push({query: {}})
				}
				//console.log(this.$route.query)
			},
		},
		created() {},
		mounted() {
			this.fetchOffers()
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcOffers {
		&__inner {}
		&__header {
			padding-bottom: 2.5em;
			display: flex;
			justify-content: space-between;
		}
		&__body {}

		&__toggleFilterBtn {
			background-color: black;
			color: @swatches[red];

			div {
				//outline: 1px solid white;
				position: relative;

				display: inline-flex;
				height: 1.1em; width: 1.1em;
				margin-right: 0.5em;
			}
			span {
				position: absolute;
				display: block;
				height: 2px; width: 100%;
				background-color: currentColor;
			}
			span:nth-child(1) {
				top: 50%;
				transform: translateY(-50%) rotate(-45deg);
			}
			span:nth-child(2) {
				top: 50%;
				transform: translateY(-50%) rotate(45deg);;
			}
		}

		&__card {
			grid-column: span 2;
			color: inherit;
		}

		&__overTitle {
			[showBorders4] & { outline: 1px solid red; }
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__title {
			[showBorders4] & { outline: 1px solid red; }
			padding-bottom: 0.5em;
		}
		&__image {
			[showBorders4] & { outline: 1px solid red; }
			width: 48%;
		}
		&__filterBtn {
			position: absolute;
			right: 0; bottom: 0;
		}

		@media @mediaQuery__md {
			&__card {
				grid-column: span 4;
			}
		}
		@media @mediaQuery__sm {
			.font--sizeSmall;

			&__card {
				grid-column: span 8;
				padding: 13px;
			}

		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
