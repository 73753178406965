<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcExperiences contentModule" :class="elmClasses">
		<div class="DcExperiences__inner" v-if="posts.length">
			<VueperSlides
				class="vueperSlides"
				:infinite="false"
				:disableArrowsOnEdges="true"
				:transitionSpeed="transitionSpeed"
				:touchable="true"
				:initSlide="initSlide"
				:slideRatio="$root.isMqBelowDt ? 1.56 : 0.5"
				:draggingDistance="70"
				:bullets-outside="false"
				@slide="afterSlide"
			>
				<VueperSlide v-for="(post, i) in posts" :key="i">
					<div slot="slideContent">
						<div class="DcExperiences__slideContent grid grid--cols-8 hSpace hSpace--colGap">
							<div class="DcExperiences__imageCell">
								<div class="DcExperiences__imageWrapper">
									<MhImage class="DcExperiences__image"
										:mode="'cover'"
										:useRelativeUrl="true"
										:useBackgroundImage="$root.isIE"
										:imageObject="post.acf.image"
										:title="_.get( post, 'acf.image.title' )"
										:alt="_.get( post, 'acf.image.alt' )"
									></MhImage>
								</div>
							</div>
							<div class="DcExperiences__textCell">
								<div class="DcExperiences__quote">
									<div class="DcExperiences__quoteText hyphenate" v-html="getTransformedHtml( post.acf.text )"></div>
									<div class="DcExperiences__quoteAuthor">
										<template v-if="post.acf.author">
											<span v-html="'— ' + post.acf.author"></span>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</VueperSlide>
			</VueperSlides>

			<!--
			<hr />
			<div v-for="post in posts" :key="post.id">
				<pre name="post">{{post}}</pre>
			</div>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import VueperSlides from  '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from  '@/components/VueperSlides/VueperSlide.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcExperiences',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
		},
		mixins: [ RestHandler ],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				listenToEvents  : false, // wird blöderweise gebraucht, weil der slider beim route change immer noch einen afterSlide() event aufruft
				transitionSpeed : 0,
				posts           : [],
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			initSlide(){ // take init slide from route query if set
				const initSlide = parseInt( this.$route.query.slide )

				return initSlide ? initSlide : 1
			},
		},
		methods: {
			fetchExperiences( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'experience_post',
						perPage : 99,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('%cthis.fetchExperiences() callback', 'background-color : lightgrey', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.posts = result

						callback()
					},
				})
			},
			afterSlide( e, slide ){
				if( this.listenToEvents ){
					const route = this.$route
					const index = slide.currentSlide.index+1

					console.groupCollapsed('DcExperiences: afterSlide() •', index)
					console.log(' e:', e)
					console.log(' slide:', slide)
					console.log(' index:', index)
					console.groupEnd()

					this.$router.push({ query : { slide : index } })
				}
			},
			getTransformedHtml( html ){
				function nl2br (str, is_xhtml) {
					if (typeof str === 'undefined' || str === null) {
						return '';
					}
					var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
					return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
				}

				return nl2br( this._.trim( html ) )
			},
		},
		created() {
			this.listenToEvents = true
		},
		mounted() {
			const route      = this.$route
			const querySlide = route.query ? route.query.slide : false

			if( !querySlide ) this.$router.replace({ query : { slide : 1 } })

			// set speed to 250 after
			// this is needed to set the initSlide without transition
			this.fetchExperiences( ()=>{
				setTimeout(()=>{ // short delay is needed
					this.transitionSpeed = 250
				}, 75)
			})
		},
		beforeDestroy(){
			this.listenToEvents = false

			//console.groupCollapsed('DcExperiences: beforeDestroy()')
			//console.groupEnd()
		}
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcExperiences {
		&__inner {}
		&__slideContent	{}

		&__imageCell ,
		&__textCell {
			[showBorders4] & {
				.outline( red );
			}

			position: relative;
		}

		&__imageWrapper {
			background-color: fade( black, 20 );

			position: relative;
			width: 100%;
			border-radius: 7px;
			overflow: hidden; // to keep the border-radius
		}
		&__image {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
		}
		&__quote {
			position: absolute;
			top: 0; left: 0;
			right: 0; bottom: 0;
			overflow-y: auto;
		}
		&__quoteText,
		&__quoteAuthor {
			border-radius: 7px;
		}
		&__quoteText {
			background-color: @swatches[yellow];
			padding-bottom: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&__quoteAuthor {
			padding-top: 3em;
			padding-bottom: 0.5em;
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			background-color: @swatches[yellow];

			p {
				display: inline;
			}
		}

		.vueperSlides {
			[showBorders4] & {
				.outline( red );
			}

			.vueperSlide { overflow: hidden; }
			&__arrows { display: none; }

			&__bullets {
				[showBorders4] & { .relative; .outline( green ); }

			}
			&__bullet {
				[showBorders4] & { outline: 1px solid red; }

				transition: all 0.1s ease;
				padding: 0.75em 0.2em;
				opacity: 0.20;
			}
			&__bullet span {
				display: inline-block;
				height: 9px; width: 9px;
				overflow: hidden;
				font-size: 0;
				background-color: black;
				border-radius: 50%;


			}
			&__bullet--active {
				opacity: 1;
			}
		}

		@media @mediaQuery__dt {
			&__inner { padding-bottom: 55px; }

			.vueperSlides__parallax-wrapper { // slide ratio
				padding-bottom: calc( 50% - @app[dtColGap]*1.25 ) !important;
			}
			.vueperSlides__bullets {
				width: calc( 50% - 10px);
			}

			&__imageCell ,
			&__textCell {
				grid-column: span 4;
			}

			&__imageWrapper { // image ratio
				padding-bottom: 100%;
			}

			&__quoteText,
			&__quoteAuthor {
				padding: 10px 17px;
			}
			&__quoteText {
				.font--sizeBig;
			}
			&__quoteAuthor {
				.font--sizeSmall;
			}
		}
		@media @mediaQuery__md {
			&__inner { padding-bottom: 30px; }

			.vueperSlides__parallax-wrapper { // slide ratio
				padding-bottom: calc( 113% - @app[mdColGap]*2 ) !important;
			}
			.vueperSlides__bullets {
				width: calc( 63% - 13px);
			}

			&__imageCell {
				grid-column: span 5;
				padding-top: 124px;
			}
			&__textCell {
				grid-column: span 3;
			}

			&__imageWrapper { // image ratio
				padding-bottom: 156%;
			}

			&__quoteText,
			&__quoteAuthor {
				padding: 10px 13px;
			}
			&__quoteText {
				.font--sizeDefault;
			}
			&__quoteAuthor {
				.font--sizeSmall;
				padding-bottom: 13px;
			}
		}
		@media @mediaQuery__sm {
			&__inner { padding-bottom: 10px; }

			.vueperSlides__parallax-wrapper { // slide ratio
				padding-bottom: calc( 175% - @app[smColGap]*2 ) !important;
			}
			.vueperSlides__bullets {
				width: 100%;
				display: none;
			}

			&__imageCell,
			&__textCell {
				grid-column: span 8;
				padding-top: 0;
			}

			&__imageWrapper { // image ratio
				padding-bottom: 100%;
			}

			&__quote {
				position: relative;
			}
			&__quoteText,
			&__quoteAuthor {
				padding: 8px 11px;
			}
			&__quoteText {
				.font--sizeSmall;
			}
			&__quoteAuthor {
				.font--sizeMini;
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
