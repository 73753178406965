<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DcTeam></DcTeam>

	2019-06-08	init

-->

<template>
	<div class="DcTeam contentModule" :class="elmClasses" v-if="staffPosts">
		<div class="DcTeam__inner background background--magenta grid grid--cols-8 hSpace hSpace--colGap vSpace vSpace--rowGap">
			<div class="DcTeam__card card" v-for="(staffPost, index) in staffPosts" :class="{ 'DcTeam__card--hasImage' : staffPost.acf.image }" :key="index">
				<div class="DcTeam__cardInner card__inner">
					<MhImage class="DcTeam__image"
						:imageObject="staffPost.acf.image"
						:title="_.get( staffPost, 'acf.image.title' )"
						:alt="_.get( staffPost, 'acf.image.alt' )"
						v-if="staffPost.acf.image"
						mode="cover"
					></MhImage>
					<h3 class="DcTeam__title" v-html="staffPost.acf.name"></h3>
					<h4 class="DcTeam__location color color--location" v-html="staffPost.acf.location"></h4>
					<div class="DcTeam__quote color color--magenta" v-html="staffPost.acf.quote"></div>
					<div class="DcTeam__grower"></div>
					<div class="DcTeam__footerText color color--black" v-html="staffPost.acf.footerText"></div>

					<!--
					<a class="DcTeam__btn btn color color--black" :href="'mailto:'+staffPost.acf.mail" target="_blank" v-if="staffPost.acf.mail">
						E-Mail <template v-if="!$root.isMqBelowMd"></template>
					</a>
					-->
					<a class="DcTeam__btn btn color color--black" :href="_.get( staffPost, 'acf.buttonUrl')" target="_blank" v-html="_.get( staffPost, 'acf.buttonText')"></a>
				</div>
			</div>
		</div>
		<!--
		<pre name="staffPosts">{{staffPosts}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcTeam',
		components: {
			MhImage,
		},
		mixins: [
			RestHandler,
		],
		props: {},
		data() {
			return {
				staffPosts : null,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				// classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchStaffPosts( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'staff_post',
						perPage  : 99,
					},
					callback : (response) => {
						const doLog  = true
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('this.fetchStaffPosts() callback', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.staffPosts = result

						callback()
					},
				})
			}
		},
		created() {},
		mounted() {
			this.fetchStaffPosts(()=>{})
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcTeam {
		.font--sizeDefault;

		&__card {
			grid-column: span 4;
			min-height: 12em;
			display: flex;
			flex-direction: column;

			.card__inner {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
			}
		}
		&__card--hasImage &__cardInner {
			@media @mediaQuery__dt { padding-left: 25%; }
			@media @mediaQuery__md { padding-left: 20%; }
			@media @mediaQuery__sm { padding-left: 110px; }
		}

		&__image {
			position: absolute;
			top: -10px; left: -12px; bottom: -10px;

			@media @mediaQuery__dt { width: 25%; }
			@media @mediaQuery__md { width: 20%; }
			@media @mediaQuery__sm { width: 110px; }
		}
		&__title {}
		&__location {}
		&__quote {
			&::before { content : "»" }
			&::after {  content : "«" }
		}
		&__grower {
			//background-color: fade( red, 20 );

			flex-grow: 1;
		}
		&__footerText {}
		&__btn {
			position: absolute;
			top: 2px; right: 0;

			&:empty { display: none; }
		}

		@media @mediaQuery__md {
			&__card {
				grid-column: span 8;
			}
		}
		@media @mediaQuery__sm {
			.font--sizeSmall;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
